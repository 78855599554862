import React, { FC, memo } from 'react'
import { Box } from '@ucheba/ui/components/Box/touch'
import { CodeTextField } from '@ucheba/ui/components/CodeTextField/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { ErrorMessage } from 'formik'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import Link from 'next/link'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ELoading } from '@ucheba/store/utils/response/types'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import styled from 'styled-components'
import { useApplicationFormCodeCore } from '../bll'
import content from '../data.json'

const BoxSC = styled(Box)`
  padding-bottom: 0;
`

const ButtonSC = styled(Button)`
  padding: 0;
  height: auto;
`

/** Ввод кода для подтверждения телефона */
const ApplicationFormRequestCode: FC = () => {
  const {
    phone,
    requestStep,
    onInputCode,
    clientRequestLoading,
    timeLeft,
    isTimeLeft,
    getNewCode,
  } = useApplicationFormCodeCore()

  return (
    <BoxSC color='white' spacing='medium'>
      <Text textAlign='center'>{`${content.code.phone.prevText} ${phone}`}</Text>

      <Text textAlign='center' html={false}>
        <Link href={requestStep}>{content.code.phone.changeLinkText}</Link>
      </Text>

      <Spacing spacing='large' />

      <Grid justifyContent='center'>
        <GridCol width='auto'>
          <CodeTextField
            onChange={onInputCode}
            disabled={clientRequestLoading === ELoading.pending}
          />
        </GridCol>
      </Grid>

      <ErrorMessage component={ErrorText} name={EDataKeys.code} />

      <Spacing spacing='small' />

      {isTimeLeft ? (
        <ButtonSC view='link' onClick={getNewCode} block size='small'>
          {content.code.getNewCodeLinkText}
        </ButtonSC>
      ) : (
        <Text textAlign='center' fontSize='small1' color='gray60'>
          {`${content.code.prevTextNewCode} ${timeLeft}`}
        </Text>
      )}
    </BoxSC>
  )
}

export { ApplicationFormRequestCode }
export default memo(ApplicationFormRequestCode)
