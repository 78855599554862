import React, { FC, memo, useMemo } from 'react'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Field } from 'formik'
import { TextField } from '@ucheba/ui/components/TextField/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Radio } from '@ucheba/ui/components/Radio/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import { useSelector } from 'react-redux'
import { clientProductsRequestsSelectors } from '@ucheba/store/client-products/requests'
import { ELoading } from '@ucheba/store/utils/response/types'
import Loader from '@ucheba/ui/components/Loader/desktop/Loader'
import { AGREEMENTS_LINKS } from '@ucheba/utils/constants/core'
import content from '../data.json'
import { IApplicationFormRequestApplicationProps } from '../types'
import { useAutoRequest } from '../bll/useAutoRequest'

/** Форма отправки заявки */
const ApplicationFormRequestApplication: FC<IApplicationFormRequestApplicationProps> = (
  props
) => {
  const { aboutParent, agreementText } = props
  const sendLoading = useSelector(clientProductsRequestsSelectors.loading)
  const apiErrors = useSelector(clientProductsRequestsSelectors.error)

  const errors = useMemo((): any => {
    if (apiErrors) {
      return apiErrors.formErrors?.reduce((acc, item) => {
        acc[item.field] = item

        return acc
      }, {})
    }

    return {}
  }, [apiErrors])

  const { isAutoReqLoading } = useAutoRequest()

  if (isAutoReqLoading) {
    return (
      <Box color='white' spacing='medium'>
        <Text fontSize='h3' fontWeight='bold' lineHeight='h2'>
          Отправка заявки
        </Text>

        <Spacing spacing='xlarge' />

        <Loader />
      </Box>
    )
  }

  return (
    <Box color='white' spacing='medium'>
      <Field
        as={TextField}
        name={EDataKeys.name}
        label={content.application.fields.name}
        required
      />

      {errors[EDataKeys.name] && (
        <ErrorText color='red'>{errors[EDataKeys.name].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      <Field
        as={TextField}
        name={EDataKeys.phone}
        type='tel'
        label={content.application.fields.phone}
        required
      />

      {errors[EDataKeys.phone] && (
        <ErrorText color='red'>{errors[EDataKeys.phone].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      {/* <Field */}
      {/*  as={TextField} */}
      {/*  name={EDataKeys.email} */}
      {/*  type='email' */}
      {/*  label={content.application.fields.email} */}
      {/*  required */}
      {/* /> */}

      {errors[EDataKeys.email] && (
        <ErrorText color='red'>{errors[EDataKeys.email].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      {aboutParent && (
        <>
          <Grid spacing='small'>
            <GridCol width='100%'>
              <Field
                as={Radio}
                type='radio'
                name={EDataKeys.isParent}
                value='false'
                label={content.application.fields.enrollee}
              />
            </GridCol>

            <GridCol width='100%'>
              <Field
                as={Radio}
                type='radio'
                name={EDataKeys.isParent}
                value='true'
                label={content.application.fields.parent}
              />
            </GridCol>
          </Grid>

          {errors[EDataKeys.isParent] && (
            <ErrorText color='red'>{errors[EDataKeys.isParent].message}</ErrorText>
          )}

          <Spacing spacing='xlarge' />
        </>
      )}

      <Field
        as={TextField}
        name={EDataKeys.specialOfferCode}
        label={content.application.fields.promocode}
      />

      {errors[EDataKeys.specialOfferCode] && (
        <ErrorText color='red'>{errors[EDataKeys.specialOfferCode].message}</ErrorText>
      )}

      <Spacing spacing='xlarge' />

      <Button
        block
        type='submit'
        color='orange'
        progress={sendLoading === ELoading.pending}
      >
        {content.application.buttonText}
      </Button>

      <Spacing spacing='small' />

      {agreementText ? (
        <Text fontSize='small1' lineHeight='small1' textAlign='center' color='gray60'>
          {`<span style="opacity: 0.5">${agreementText}</span>`}
        </Text>
      ) : (
        <Text fontSize='small1' lineHeight='small1' textAlign='center' html={false}>
          <span style={{ opacity: '0.5' }}>
            Продолжая, вы соглашаетесь на обработку персональных данных на условиях{' '}
            <a
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
              href={`${AGREEMENTS_LINKS.personalData}`}
            >
              Согласия на обработку персональных данных
            </a>{' '}
            и принимаете условия{' '}
            <a
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
              href={`${AGREEMENTS_LINKS.agreementsForMain}`}
            >
              Пользовательского соглашения.
            </a>
          </span>
        </Text>
      )}
    </Box>
  )
}

export { ApplicationFormRequestApplication }
export default memo(ApplicationFormRequestApplication)
