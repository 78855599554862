/* eslint-disable react/require-default-props */
import React, { FC } from 'react'

import { Box } from '@ucheba/ui/components/Box/touch'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Divider } from '@ucheba/ui/components/Divider/desktop'
import { useStepName } from '../bll'
import { EApplicationFormSteps, QuestProps } from '../types'
import content from '../data.json'

const ApplicationFormHeader: FC<QuestProps> = ({ isEgeQuest = false }) => {
  const stepName = useStepName()

  if (stepName === EApplicationFormSteps.final) {
    return null
  }

  if (stepName === EApplicationFormSteps.code) {
    return (
      <div>
        <Box spacing='small'>
          <Text fontWeight='semibold' fontSize='medium' textAlign='center'>
            {content.code.title}
          </Text>
        </Box>

        <Divider spacing='null' />
      </div>
    )
  }

  if (stepName === EApplicationFormSteps.parent) {
    return (
      <div>
        <Box spacing='small'>
          <Text fontWeight='semibold' fontSize='medium' textAlign='center'>
            {content.parent.title}
          </Text>

          <Text fontSize='medium' textAlign='center'>
            {content.parent.hint}
          </Text>
        </Box>

        <Divider spacing='null' />
      </div>
    )
  }

  return (
    <div>
      <Box spacing='small'>
        <Text fontWeight='semibold' fontSize='medium'>
          {isEgeQuest ? content.messengers.title : content.application.title}
        </Text>
      </Box>

      <Divider spacing='null' />
    </div>
  )
}

export default ApplicationFormHeader
